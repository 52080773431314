<template>
  <div class="home">
    <ModalCalculator msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ModalCalculator from '@/components/Inventory/ModalCalculator.vue'


export default {
  name: 'HomeView',
  components: {
    ModalCalculator

  }
}
</script>
