<template>
    <div>
      <NavBar />
     <router-view/>

      <FooterComponent/>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";
// import CreditSolutionsComponents from "@/components/CreditSolutionsComponents.vue";

    export default {
        name: 'CreditSolutionsView',
        components:{
            NavBar,FooterComponent
        }
    }
</script>

<style lang="scss" scoped>

</style>