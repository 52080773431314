<template>
    <div>
        <NavBar />
        <div class="element-center" style="height:calc(100vh - 267px)">
        <h1 class="bar-code" >404 Page not found</h1>
        </div>
      <FooterComponent/>
    </div>
</template>

<script>
  import NavBar from "@/components/NavBar.vue";
  import FooterComponent from "@/components/FooterComponent.vue";
    export default {
        name : 'NotFoundView',
        components: {
      NavBar,FooterComponent
    },
    }
</script>

<style scoped>

</style>
