<template>
  <div>
    <section class="container-fluid">
      <div class="row photo-team contact-height" style="position: relative">
        <div
          class="col text-center d-flex justify-content-center align-items-center contact-height"
        >
          <h1 class="text-white"><strong>Our Team</strong></h1>
        </div>
      </div>
    </section>
    <div class="container py-5">
      <div class="row">
        <div class="col-12">
          <p class="text-center mb-0">
            Our dedicated team of seasoned professionals are here to help make
            this business transaction, easy and stress-free. We trust that you
            will find our approach to this aspect of purchasing or leasing a
            vehicle to be rewarding and beneficial to yourself and others that
            you know could benefit from our services.
          </p>
        </div>
      </div>
      <div class="row pt-5">
        <div
          class="col-lg-3 col-md-6 col-12 mb-5"  @click="clickDetails(member)"
          v-for="(member, index) in teamData"
          :key="index"
        >
          <div
            class=""
            style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
          >
            <img :src="member.photo" width="100%" height="auto" />
            <div class="p-3">
              <h3 style="color: #07529d" class="text-capitalize">
                {{ member.first_name }} {{ member.last_name }}
              </h3>
              <h5 class="text-capitalize">{{ member.position }}</h5>


                <teleport to="#modal-root">
                  <ModalCalculator
                    v-show="showModal"
                    @close="showModal = false"
                  >
                    <div
                      class="modal fade show"
                      id="description"
                      aria-modal="true"
                      role="dialog"
                      style="display: block; padding-left: 0px"
                    >
                      <div class="modal-dialog modal-lg">
                        <div
                          class="modal-content top-border-modal"
                          style="border-top: 5px #07529d solid"
                        >
                          <div class="modal-body">
                            <div class="row p-4">
                              <div
                                class="col-sm-4 col-12 "
                                id="staff_image"
                              >
                                <img
                                  width="100%"
                                  height="auto"
                                  :src="selectedMember.photo"
                                  class="fluid-image"
                                  :alt="selectedMember.first_name "
                                  decoding="async"
                                  loading="lazy"
                                />
                              </div>

                              <div
                                class="col-sm-8 col-12 mobile-view-modal-name"
                              >
                                <h2 class="header-color pt-3 text-capitalize" id="label_title">
                                 {{selectedMember.first_name }} {{  selectedMember.last_name}}
                                </h2>
                                <p class="text-secondary emptitle pt-3 text-capitalize">
                                  {{selectedMember.position}}
                                </p>
                                <p class="pt-3">
                                  <i
                                    class="fa fa-phone fa-lg header-color"
                                    aria-hidden="true"
                                  ></i>
                                  <span class="phone pt-3 ps-3">{{ selectedMember.phone }}</span>
                                </p>
                                <p class="pt-3">
                                  <i
                                    class="fa fa-envelope fa-lg  header-color "
                                    aria-hidden="true"
                                  ></i>
                                  <span class="email pt-3 ps-3"
                                    >{{selectedMember.email}}</span
                                  >
                                </p>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalCalculator>


                </teleport>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ModalCalculator from "@/components/Inventory/ModalCalculator.vue";

export default {
  name: "TeamCompnent",
  components: { ModalCalculator },
  data() {
    return {
      teamData: {},
      showModal: false,
      selectedMember:{}
    };
  },
  methods: {
    getTeam() {
      axios({
        method: "GET",
        url: "/team.json",
        responseType: "json",
      })
        .then((res) => (this.teamData = res.data))
        .catch((err) => console.log(err));
    },
    clickDetails(member){
      this.selectedMember = member
      this.showModal=true;
    }
  },
  created() {
    this.getTeam();
  },
};
</script>
<style scoped>
.photo-team {
  background: url("/public/assets/ourteam.jpg") !important;
  padding: 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 200px;

  color: white;
}
@media only screen and (max-width: 767px) {
  photo-team {
    background: url("/public/assets/ourteam.jpg") !important;
    padding: 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 150px;
    color: white;
  }
}
</style>