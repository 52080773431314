<template>
  <div>
    <section class="hero hero-lease" style="position: relative">
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-12 box-position">
            <div class="hero-box">
              <p class="header-color fw-bolder">                Commercial Vehicle Leasing
              </p>
              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                Access to all makes and models
              </p>
              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                Flexible terms to match your Business
              </p>
              <div class="px-4 pb-0 text-center">
                <a class="btn btn-sm" href="tel:2045150757">Call now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="letter-spacing"
      style="padding-top: 100px; padding-bottom: 100px"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3 class="header-color">Commercial fleet vehicles leasing</h3>
            <p class="px-md-5">
              Vickar Financial Services is a Family-owned and operated business
              Family-owned and operated business that has been providing Leasing
              solutions to all types of life for over 25 years
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 hero hero-isuzu element-left">
            <div class="hero-box">
              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                Lowest fleet pricing and manufacturer programs available
              </p>

              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                Extensive network of fleet focused dealers
              </p>
              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                We have access to various kinds of decks, dump boxes, cubes,
                utility bodies, plus much more!
              </p>
              <div class="px-4 pb-0 text-center">
                <a class="btn btn-sm" href="tel:2045150757">Call now</a>
              </div>
            </div>
          </div>
          <div class="col-md-6 hero hero-ford element-right">
            <div class="hero-box">
              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                Customized fleet Pricing
              </p>

              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                Volume purchasing power
              </p>
              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                A large vehicle sourcing vehicle
              </p>
              <p>
                <i
                  class="fa fa-check fw-bolder header-color"
                  aria-hidden="true"
                ></i>
                Whether youy fleet consist of 3 or 30 vehicles, we have exactly
                what you're looking for
              </p>
              <div class="px-4 pb-0 text-center">
                <a class="btn btn-sm" href="tel:2045150757">Call now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-4 col-12 mb-5 ">
            <div class="element-center">
          <img src="https://vickarfinancial.ca/wp-content/uploads/2023/02/feat2.png" width="50%" height="auto"/></div>
          <h4 class="text-center header-color mt-3">We make it easy</h4>
          <p>
            At Vickar Financial Services, we believe that the vehicle that you
            want should be the one that fits seamlessly into your lifestyle.
            This means that we can source the exact make and model that you
            want, without being tied to any specific manufacturer.We Make It
            Easy
          </p>
        </div>

        <div class="col-md-4 col-12 mb-5">
            <div class="element-center">
          <img
            width="50%"
            height="auto"
            src="https://vickarfinancial.ca/wp-content/uploads/2023/02/feat3.png"

          /></div>
          <h4 class="text-center header-color mt-3">Best affordable lease options</h4>
          <p>
            Let us help you manage the cost of your vehicles. We know that
            running a business can be hard, so we’ll work with you to find the
            best terms for your needs.
          </p>
        </div>

        <div class="col-md-4 col-12 mb-3 centered_img-50 zero_padding-xs">
            <div class=" element-center">        <img
            width="50%"
            height="auto"
            src="https://vickarfinancial.ca/wp-content/uploads/2023/02/feat1.png"

          /></div>

          <h4 class="text-center header-color mt-3">1000+ vehicles in inventory</h4>
          <p class="">
            We’re here to help you find the truck that fits your needs. From our
            inventory to vehicle sourcing, we’re confident that we can help you
            find the right fit.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeaseComponent",
};
</script>

<style scoped>
.hero-isuzu .hero-box {
  width: 400px !important;
}
.hero-ford .hero-box {
  max-width: 400px;
}
.hero {
  background-position: center top;
  background-repeat: no-repeat !important;
  background-size: cover;
}
.hero-lease {
  background-image: url(https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/lease_hero.png) !important;
  height: 450px;
}
.hero-isuzu {
  background-image: url(https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/isuzu_leasing.png) !important;
  height: 450px;
}
.hero-ford {
  background-image: url(https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/ford_leasing.png) !important;
  height: 450px;
}
.hero-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
}
.box-position {
  display: flex;

  flex-direction: column;
  align-items: end;
  justify-content: end;
  padding: 3rem;
}
.element-left {
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 2rem;
}
.element-right {
  display: flex;
  justify-content: start;
  align-items: end;
  padding: 2rem;
}

@media only screen and (max-width: 767px) {
  .hero-lease {
    height: 300px;
  }
  .hero-box h4 {
    font-size: 16px !important;
  }
  .box-position {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .element-left {
    justify-content: center;
    align-items: center;
  }
  .element-right {
    justify-content: center;
    align-items: center;
  }
}
</style>