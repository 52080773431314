<template>
  <div>
    <input type="text" class="js-range-slider-2" :min="yearMin" :max="yearMax" />
  </div>
</template>

  <script>
import $ from "jquery";
import "ion-rangeslider/css/ion.rangeSlider.min.css";
import "ion-rangeslider/js/ion.rangeSlider.min.js";

export default {
  name: "YearSlider",
  props:{
    yearMin: Number,
    yearMax:Number
  },
  data(){
    return{
      minY: this.yearMin,
      maxY: this.yearMax

    }
  },

  mounted() {
    $(".js-range-slider-2").ionRangeSlider({
      type: "double",
      min: this.minY,
      max: this.maxY,
      from: this.minY,
      to: this.maxY,
      step: 1,
      skin:'round',
      onFinish: (data) => {
            this.$emit('update-year', { from: data.from, to: data.to });
      }

    });
  },
};
</script>

  <style scoped>

</style>
