<template>
    <div>
        BusinessSolutionsComponent
    </div>
</template>

<script>
    export default {
        name:'BusinessSolutionsComponent'

    }
</script>

<style scoped>

</style>