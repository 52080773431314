    <template>
  <div class="container">
    <div class="row py-5">
      <div class="col-12">
        <h1 class="text-center header-color">Challenged credit program</h1>
        <p class="text-center">
          Feel settled faster - let us help you buy your first car in Canada.
        </p>
        <hr class="mb-0" />
        <div class="element-center py-5">
          <div class="iframe-centre-wrapper">
            <div class="iframe-wrapper">
              <iframe
                src="https://www.youtube.com/embed/TIHTHrj0hzs"
                title="YouTube video player"
                frameborder="0"
                class="iframe-position"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <div class="newcomer-data text-start">
          <h3 class="header-color">What is a Challenged credit?</h3>
          <div class="answer padding-bottom-4">
            <p class="mb-0">
              Even if you have bad credit, it’s possible to get a car loan
              despite a low credit score. We are the Bank! Vickar Financial
              Services is the only vehicle financing operation in Manitoba that
              lends our own money, which is unique in itself. Lending our own
              money means more approvals at better interest rates.
            </p>
          </div>
          <h3 class="header-color">How do I apply?</h3>
          <div class="answer padding-bottom-4">
            <p class="mb-0">
              You can apply for a vehicle in the way that you’re most
              comfortable! All information given by our clients is fully secure
              and confidential, regardless of the method that you choose.
            </p>
          </div>
          <h5 class="header-color">
            <strong
              ><i class="fa fa-check" aria-hidden="true"></i> Apply Online:
            </strong>
          </h5>
          <div class="answers padding-bottom-4 ms-3">
            <p class="mb-0">
              Simply click the “Apply Now” button below, complete the credit
              application, and click submit! One of our experts will receive
              your information and will be in contact within one business day to
              verify your information, discuss your vehicle preference, and
              determine what fits your lifestyle best.
            </p>
          </div>
          <h5 class="header-color">
            <strong
              ><i class="fa fa-check" aria-hidden="true"></i> Apply via
              email:</strong
            >
          </h5>
          <div class="answers padding-bottom-4 ms-3">
            <p class="mb-0">
              Send us an email at <a href="mailto:jason.rajpoot@vickar.com" class="head_color"
                >jason.rajpoot@vickar.com</a
              >with your full name,
              phone number, vehicle preference, and budget. One of our experts
              will be in touch within one business day to get started on finding
              the perfect vehicle for your driveway!
            </p>
          </div>
          <h5 class="header-color">
            <strong><i class="fa fa-check" aria-hidden="true"></i></strong
            ><b> Apply via phone: </b>
          </h5>
          <div class="answers padding-bottom-4 ms-3">
            <p class="mb-0">
              Just give us a call at
              <a href="tel:2045150757" class="head_color">(204) 515-0757</a> and
              we can securely take down your information and get your
              application started right away.
            </p>
          </div>
          <h5 class="header-color">
            <strong><i class="fa fa-check" aria-hidden="true"></i> </strong
            ><b> Apply in person: </b>
          </h5>
          <div class="answers ms-3">
            <p class="mb-0">
              We know that sometimes it’s just more fun to come to the
              dealership and have a look around and see people face-to-face! If
              this is your thing, we’d love for you to come in, have a coffee,
              and take a look around. Reach out to us by email at
              <a href="mailto:jason.rajpoot@vickar.com" class="head_color"
                >jason.rajpoot@vickar.com</a
              >
              or by phone at <a href="tel:2045150757">(204) 515-0757</a> to book
              an appointment. You can find us at 1501 Dugald Road in Winnipeg,
              MB.
            </p>
          </div>

       <div class="cta-buttons element-center padding-top-4" >
            <div>
            <router-link :to="{name: ''}" class="btn me-3   ">Apply Now</router-link>

            <router-link :to="{name: 'contact'}" class="btn">Contact us </router-link>
        </div></div>
    </div>
      </div>
    </div>
  </div>
</template>

    <script>
export default {
  name: "CcreditView",
  components: {},
};
</script>

    <style scoped>

</style>