<template>
     <div>
<div v-if="pageType =='vdp'">
    <p style="display:block;line-height:1" class="mb-2 text-secondary ">Estimated : <span style="font-size:0.7em;position:relative;top:-3px;font-weight:bold">$</span><b>{{ formattedPrice(dispOutput.total) }} </b> bi-weekly</p>
    <p class="mb-2 text-secondary "> Cash Down : <span style="font-size:0.7em;position:relative;top:-3px;font-weight:bold">$</span><b>{{downPayment }}</b></p>
    <p class="text-secondary "> Terms : <b>{{dispOutput["term"]  }}</b>  Months</p>
  </div>
  <div v-if="pageType =='srp'">
    <p style="display:block;line-height:1" class="text-secondary "><b>Est : </b> <span style="font-size:0.7em;position:relative;top:-3px;font-weight:bold">$</span>{{ formattedPrice(dispOutput.total) }} b/w</p>
    <p class="text-secondary "> <b>Cash Down : </b> <span style="font-size:0.7em;position:relative;top:-3px;font-weight:bold">$</span>{{downPayment }}</p>
  </div>
  <span v-if="pageType =='single'">
 <span style="font-size:0.7em;position:relative;top:-3px;font-weight:bold">$</span>{{ formattedPrice(dispOutput.total) }}</span>

</div>

</template>

<script>
export default {
  name: "LeaseCalculator",
  props:{
    initialPrice:Number,
    vehiclePrice: Number,
    vehicleCondition: String,
    downPayment: Number,
    taxSelected:Boolean,
    termsSelected:Number,
    pageType:String
  },

  data() {
    return {
      interest: 9.99,
      termspayment: [
        {
          id: 1,
          term: 24,
          residue: "",
          output: "",
        },
        {
          id: 2,
          term: 36,
          residue: "",
          output: "",
        },
        {
          id: 3,
          residue: "",
          term: 48,
          output: "",
        },
      ],
      dispOutput: {},
    };
  },
  methods: {
    calculateprice(terms, cdown) {

      if (this.vehicleCondition == "Used") {
        if (terms == 1) {
          this.termspayment[terms - 1].residue = this.vehiclePrice * 0.6;
        } else if (terms == 2) {
          this.termspayment[terms - 1].residue = this.vehiclePrice * 0.45;
        } else if (terms == 3) {
          this.termspayment[terms - 1].residue = this.vehiclePrice * 0.3;
        }
      }
      if (this.vehicleCondition == "New") {
        if (terms == 1) {
          this.termspayment[terms - 1].residue = this.vehiclePrice * 0.7;
        } else if (terms == 2) {
          this.termspayment[terms - 1].residue = this.vehiclePrice * 0.58;
        } else if (terms == 3) {
          this.termspayment[terms - 1].residue = this.vehiclePrice * 0.48;
        }
      }

      this.dispOutput["term"] = this.termspayment[terms - 1].term;
      this.dispOutput["residue"] = this.termspayment[terms - 1].residue;
      this.dispOutput["mf"] = parseFloat(this.interest) / parseFloat(2400);
      // this.dispOutput['mf'] = 0.0076923;

      this.dispOutput["dmv"] = (parseFloat(this.initialPrice) -
          parseFloat(this.termspayment[terms - 1].residue) -
          cdown) /parseFloat(this.termspayment[terms - 1].term);
           // (parseFloat(this.carprice) -
        //   parseFloat(this.termspayment[terms - 1].residue) -
        //   cdown) /
        // parseFloat(this.termspayment[terms - 1].term);

      // this.dispOutput["tin"] =
      //   (parseFloat(this.carprice) +
      //     parseFloat(this.termspayment[terms - 1].residue - cdown)) *
      //   this.dispOutput["mf"];
      this.dispOutput["tin"] =
        (parseFloat(this.initialPrice) +
          parseFloat(this.termspayment[terms - 1].residue - cdown)) *
        this.dispOutput["mf"];

      if (this.taxSelected) {
        this.dispOutput["total"] =
          (((this.dispOutput["dmv"] + this.dispOutput["tin"]) * 12) / 26) *
          1.12;
        if (this.dispOutput["total"] < 0) {
          this.dispOutput["total"] = 0;
        }
      } else {
        this.dispOutput["total"] =
          ((this.dispOutput["dmv"] + this.dispOutput["tin"]) * 12) / 26;
        if (this.dispOutput["total"] < 0) {
          this.dispOutput["total"] = 0;
        }
      }
    },
    formattedPrice(a) {
      const price = parseFloat(a);
      if (!isNaN(price)) {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "decimal",
          currency: "USD",
          minimumFractionDigits: 0,
        });
        return formatter.format(price);
      } else {
        return "Invalid Price";
      }
    },
  },
  watch: {
    downPayment: function (val) {
      this.calculateprice(this.termsSelected, val);
    },
    termsSelected: function (val) {
      this.calculateprice(val, this.downPayment);
    },

    taxSelected: function () {
      this.calculateprice(this.termsSelected, this.downPayment);
    },
  },


mounted() {

      this.calculateprice(1, 2000);
},
    }

</script>

<style scoped>
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1,
p {
  margin-bottom: 0;
}
</style>