<template>
  <div>
    <input
    type="text"
      id="range-slider"
      name="range-slider"
    />
  </div>

</template>

  <script>
import $ from "jquery";
import "ion-rangeslider/css/ion.rangeSlider.min.css";
import "ion-rangeslider/js/ion.rangeSlider.min.js";

export default {
  name: "PriceSlider",
  props: {
    priceMax: Number,
    priceMin: Number,
    fixedMaxprice:Number,
    fixedMinprice:Number,

  },
  data() {
    return {

      sliderInstance: null,

    };
  },
  methods: {
    initializeRangeSlider() {
       const options = {
        type: "double",
        min: this.fixedMinprice,
        max: this.fixedMaxprice,
        from: this.priceMin,
        to: this.priceMax,
        step: 1000,
        skin:'round',
        onFinish: (data) => {
          this.$emit("update-range", { from: data.from, to: data.to });
        },
      }

      this.sliderInstance = $(this.$el)
        .ionRangeSlider(options)
        .data("ionRangeSlider");
    },
    destroyRangeSlider() {
      if (this.sliderInstance) {
        this.sliderInstance.destroy();
      }
    },
  },


  beforeUnmount() {
    this.destroyRangeSlider();
  },
  beforeUpdate() {
    this.destroyRangeSlider();
  },
  updated() {
    this.initializeRangeSlider();
  },
  mounted(){
    this.initializeRangeSlider();
  },
};
</script>

  <style scoped>

</style>
