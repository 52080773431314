<template>
  <section class="margin-head">
    <div class="container">
      <div class="row py-5">
        <div class="col-12 mb-3">
            <h4 class="header-color">
                Aluminum & Steel dump
              </h4>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-3">
          <img
            class="w-100 img-fluid "
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Steel_Dump_1.jpg"
            alt="Steel dump"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-3">
          <img
          class="w-100 img-fluid "
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Steel_Dump_2.jpg"
            alt="Steel dump"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
          <img
          class="w-100 img-fluid "
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Ford_Dump_Box_2.jpg"
            alt="Steel dump"
          />
        </div>

        <div class="col-12">
            <p class="text-center mb-0"> The cab forward design allows you to get in and out of tight places
            that a normal truck could not even attempt. We can offer a wide
            range of aluminum steel combo decks and dump boxes. Our wide range
            of manufacturing suppliers can provide the right dump box for your
            needs. Available in Gas or Diesel
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-3">
            <h4 class="header-color">
              Cube Box
              </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-3">
          <img
            class="w-100 img-fluid"
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Cube_Box_1.jpg"
            alt="Cube Box"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-3">
          <img
            class="w-100 img-fluid "
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Cube_Box_2.jpg"
            alt="Cube Box"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-3">
          <img
            class="w-100 img-fluid"
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Cube_Box_3.jpg"
            alt="Cube Box"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
            <p class="text-center mb-0">            The cab forward truck is the best delivery truck you could ever
            have. We have light weight boxes from 16 to 20 ft. longer boxes can
            be available on special orders.Give us a call or email and see how
            we can meet your needs. Available in Gas or Diesel.
          </p>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col-12 mb-3">
            <h4 class="header-color">
                Deck Trucks
              </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-3">
          <img
            class="w-100 img-fluid"
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Deck_Trucks_1.jpg"
            alt="Deck Box"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-3">
          <img
            class="w-100 img-fluid"
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Deck_Trucks_2.jpg"
            alt="Deck Box"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-3">
          <img
            class="w-100 img-fluid"
            src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/leasing/leasing_fleet/Ford_Flat_Deck.jpg"
            alt="Deck Box"
          />
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-12">
          <p class="text-center mb-0">
            These trucks can be outfitted to what you see that’s a flat deck
            steel or aluminum with or without a rear lift.. A dump option can
            also be added. Available in Gas or Diesel. Call us for more
            information.
          </p>
        </div>
      </div>
    </div>
    <div class="text-center pb-5">
      <a href="/contact-us" class="btn btn-primary btncolor"> Contact Us </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "FleetComponent",
};
</script>

<style lang="scss" scoped>
</style>