<template>
  <div class="container">
    <div class="row py-5">
      <div
        class="col-12 py-5  application-wrapper"
        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
      >
        <div>
          <h1 class="text-center header-color">Lease Application</h1>
          <div class="progress-bar d-flex flex-row py-4">
            <div
              class="progress-circle element-center fw-bolder progress-initial"
              :class="[
                { 'color-bg': formShow === 'form2' },
                { 'color-bg': formShow === 'form3' },
                { 'color-bg': formShow === 'form4' },
              ]"
            >
              1
            </div>
            <div
              class="progress-rect element-center"
              :class="[
                { 'color-bg': formShow === 'form2' },
                { 'color-bg': formShow === 'form3' },
                { 'color-bg': formShow === 'form4' },
              ]"
            >
              <div></div>
            </div>
            <div
              class="progress-circle element-center fw-bolder"
              :class="[
                { 'progress-initial': formShow === 'form2' },
                { 'color-bg': formShow === 'form3' },
                { 'color-bg': formShow === 'form4' },
              ]"
            >
              2
            </div>
            <div
              class="progress-rect element-center"
              :class="[
                { 'color-bg': formShow === 'form3' },
                { 'color-bg': formShow === 'form4' },
              ]"
            >
              <div></div>
            </div>
            <div
              class="progress-circle element-center fw-bolder"
              :class="
                ([{ 'progress-initial': formShow === 'form3' }],
                { 'color-bg': formShow === 'form4' })
              "
            >
              3
            </div>
            <div
              class="progress-rect element-center"
              :class="[{ 'color-bg': formShow === 'form4' }]"
            >
              <div></div>
            </div>
            <div
              class="progress-circle element-center fw-bolder"
              :class="[{ 'progress-initial': formShow === 'form4' }]"
            >
              4
            </div>
          </div>
        </div>
        <div id="form1" class="form form-active" v-show="formShow == 'form1'">
          <div class="row">
            <div class="col-12">
              <h4 class="">Step 1: Select vehicle type</h4>
              <div class="vehicle-type py-4">
                <label class="custom-radio">
                  <input
                    type="radio"
                    id="vehicletype-car"
                    v-model="vehicleType"
                    value="car"
                  />
                  <span class="rad-button m-0 p-2"
                    ><img
                      src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/credit_car.png"
                      width="100%"
                      height="auto"
                  /></span>
                </label>
                <label class="custom-radio">
                  <input
                    type="radio"
                    id="vehicletype-suv"
                    v-model="vehicleType"
                    value="suv"
                  />
                  <span class="rad-button m-0 p-2"
                    ><img
                      src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/credit_suv.png"
                      width="100%"
                      height="auto"
                  /></span>
                </label>
                <label class="custom-radio">
                  <input
                    type="radio"
                    id="vehicletype-van"
                    v-model="vehicleType"
                    value="van"
                  />
                  <span class="rad-button m-0 p-2"
                    ><img
                      src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/credit_van.png"
                      width="100%"
                      height="auto"
                  /></span>
                </label>
                <label class="custom-radio">
                  <input
                    type="radio"
                    id="vehicletype-truck"
                    v-model="vehicleType"
                    value="truck"
                  />
                  <span class="rad-button m-0 p-2"
                    ><img
                      src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/credit_truck.png"
                      width="100%"
                      height="auto"
                  /></span>
                </label>
                <span id="error-vehicleType" class="errors small text-danger">{{
                  errorVehicleType
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div id="form2" class="form" v-show="formShow == 'form2'">
          <h4>Step 2: Contact Information</h4>
          <div class="address-details pt-4">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="mb-4" style="position: relative;text-align: left">
                  <input
                    type="text"
                    class="w-100"
                    placeholder=""
                    v-model="form2.vmodel.fullName"
                  />
                  <span class="input-details">Full name<sup class='text-danger'>*</sup></span>
                  <span id="error-fullName" class="errors small text-danger">{{
                    form2.error.errorfullName
                  }}</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="mb-4" style="position: relative;text-align: left">
                  <input
                    type="text"
                    class="w-100"
                    v-model="form2.vmodel.emailaddress"
                    placeholder=""
                  />
                  <span class="input-details">Email Address<sup class='text-danger'>*</sup></span>
                  <span
                    id="error-emailaddress"
                    class="errors small text-danger"
                    >{{ form2.error.errorEmailAddress }}</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="mb-4" style="position: relative;text-align: left">
                  <input
                    type="text"
                    class="w-100"
                    placeholder=""
                    v-model="form2.vmodel.phone"
                  />
                  <span class="input-details">Phone<sup class='text-danger'>*</sup></span>
                  <span id="error-phone" class="errors small text-danger">{{
                    form2.error.errorPhone
                  }}</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="mb-4" style="position: relative;text-align: left">
                  <input
                    type="text"
                    class="w-100 datepicker"
                    placeholder=""
                    v-model="form2.vmodel.dob"
                  />
                  <span class="input-details">Date of Birth<sup class='text-danger'>*</sup></span>
                  <span id="error-dob" class="errors small text-danger">{{
                    form2.error.errorDOB
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="form3" class="form" v-show="formShow == 'form3'">
          <h4>Step 3: Address Information</h4>
          <div class="pt-4">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="mb-4" style="position: relative;text-align: left">
                  <input
                    type="text"
                    class="w-100"
                    placeholder=""
                    v-model="form3.vmodel.address"
                  />
                  <span class="input-details">Address<sup class='text-danger'>*</sup></span>
                  <span id="error-address" class="errors small text-danger">{{
                    form3.error.errorAddress
                  }}</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <input
                    type="text"
                    class="w-100"
                    placeholder=""
                    v-model="form3.vmodel.city"
                  />
                  <span class="input-details">City<sup class='text-danger'>*</sup></span>
                  <span id="error-city" class="errors small text-danger">{{
                    form3.error.errorCity
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <select v-model="form3.vmodel.province">
                    <option class='' value="">Select province</option>
                    <option value="Alberta">Alberta</option>
                    <option value="British Columbia">British Columbia</option>
                    <option value="Manitoba">Manitoba</option>
                    <option value="New Brunswick">New Brunswick</option>
                    <option value="New Foundland and Labrador">
                      New Foundland and Labrador
                    </option>
                    <option value="Nova Scotia">Nova Scotia</option>
                    <option value="Nunavut">Nunavut</option>
                    <option value="Ontario">Ontario</option>
                    <option value="Prince Edward Islands">
                      Prince Edward Islands
                    </option>
                    <option value="Quebec">Quebec</option>
                    <option value="Saskatchewan">Saskatchewan</option>
                    <option value="Yukon">Yukon</option>
                  </select>

                  <span class="input-details"> Province<sup class='text-danger'>*</sup></span>
                  <span id="error-province" class="errors small text-danger">{{
                    form3.error.errorProvince
                  }}</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <input
                    type="text"
                    class="w-100"
                    placeholder=""
                    v-model="form3.vmodel.postal"
                  />
                  <span class="input-details">Postal Code<sup class='text-danger'>*</sup></span>
                  <span id="error-postal" class="errors small text-danger">{{
                    form3.error.errorPostal
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <select v-model="form3.vmodel.currentResidence">
                    <option value="">Select</option>
                    <option value="<1>">Less than 1</option>

                    <option value="1">1</option>

                    <option value="2">2</option>
                    <option value="3+">More than 3</option>
                  </select>
                  <span class="input-details">Years at current residence<sup class='text-danger'>*</sup></span>
                  <span
                    id="error-currentResidence"
                    class="errors small text-danger"
                    >{{ form3.error.errorCurrentResidence }}</span
                  >
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <select v-model="form3.vmodel.residenceType">
                    <option value="">Select</option>
                    <option value="Rent">Rent</option>
                    <option value="Own">Own</option>
                  </select>
                  <span class="input-details">Residence Type<sup class='text-danger'>*</sup></span>
                  <span
                    id="error-residenceType"
                    class="errors small text-danger"
                    >{{ form3.error.errorResidenceType }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="form4" class="form" v-show="formShow == 'form4'">
          <div class="pt-4">
            <h4>Step 4: Additional Details</h4>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <input
                    type="text"
                    class="w-100"
                    placeholder=""
                    v-model="form4.vmodel.companyName"
                  />
                  <span class="input-details">Company Name</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <select v-model="form4.vmodel.years">
                    <option value="">Years with company</option>
                    <option value="<1">Less than 1</option>
                    <option value="1+">1+</option>
                    <option value="2+">2+</option>
                    <option value="3+">3+</option>
                  </select>
                  <span class="input-details">Years with company</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <input
                    type="text"
                    class="w-100"
                    placeholder=""
                    v-model="form4.vmodel.occupation"
                  />
                  <span class="input-details">Occupation</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <select v-model="form4.vmodel.income">
                    <option value="" selected="">
                      Select Bi-weekly Income
                    </option>
                    <option value="$300-$399">$300-$399</option>
                    <option value="$400-$599">$400-$599</option>
                    <option value="$600-$799">$600-$799</option>
                    <option value="$800-$1199">$800-$1199</option>
                    <option value="$1200+">$1200+</option>
                  </select>

                  <span class="input-details">Income</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <select v-model="form4.vmodel.employmentStatus">
                    <option value="">Select</option>
                    <option value="Self Employed">Self Employed</option>
                    <option value="Employed">Employed</option>
                    <option value="Student">Student</option>
                    <option value="Retired">Retired</option>
                    <option value="Other">Other</option>
                  </select>
                  <span class="input-details">Employment Status<sup class='text-danger'>*</sup></span>
                  <span
                    id="error-employmentStatus"
                    class="errors small text-danger"
                    >{{ form4.error.errorEmploymentStatus }}</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="mb-4" style="position: relative;text-align: left">

                  <select v-model="form4.vmodel.tradeIn">
                    <option value="" selected="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <span class="input-details">Trade-in?<sup class='text-danger'>*</sup></span>
                  <span id="error-tradeIn" class="errors small text-danger">{{
                    form4.error.errorTradein
                  }}</span>
                </div>
              </div>
              <div class="col">
               <div class="mb-4" style="position: relative;text-align: left">

                  <select v-model="form4.vmodel.budget">
                    <option value="">Budget</option>
                    <option value="<1">Less than 1</option>
                    <option value="1+">1+</option>
                    <option value="2+">2+</option>
                    <option value="3+">3+</option>
                  </select>
                  <span class="input-details">Budget</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="cta-buttons element-center">
              <div>
                <span
                  class="me-3 btn btn-sm prev"
                  @click="changeForm('prev')"
                  v-show="formShow != 'form1'"
                  >Previous</span
                >
                <span
                  class="me-3 btn  btn-sm  next"
                  @click="changeForm('next')"
                  v-if="formShow != 'form4'"
                >
                  Next</span
                >
                <span
                  class="me-3 btn  btn-sm  submit"
                  @click="changeForm('submit')"
                  v-if="formShow == 'form4'"
                >
                  Submit</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationView",

  data() {
    return {
      formShow: "form1",
      vehicleType: "",
      errorVehicleType: "",

      form2: {
        vmodel: {
          emailaddress: "",
          fullName: "",
          phone: "",
          dob: "",
        },
        error: {
          errorEmailAddress: "",
          errorPhone: "",
          errorDOB: "",
          errorfullName: "",
        },
      },
      form3: {
        vmodel: {
          address: "",
          city: "",
          province: "",
          postal: "",
          currentResidence: "",
          residenceType: "",
        },
        error: {
          errorAddress: "",
          errorCity: "",
          errorProvince: "",
          errorPostal: "",
          errorCurrentResidence: "",
          errorResidenceType: "",
        },
      },
      form4: {
        vmodel: {
          companyName: "",
          years: "",
          occupation: "",
          income: "",
          budget: "",
          employmentStatus: "",
          tradeIn: "",
        },
        error: {
          errorEmploymentStatus: "",
          errorTradein: "",
        },
      },
    };
  },
  methods: {
    changeForm(ele) {
      var elements = document.getElementsByClassName("form");
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];

        if (element.classList.contains("form-active")) {
          if (ele == "next") {
            if (this.formShow == "form1") {
              if (this.vehicleType) {
                element.classList.remove("form-active");

                this.formShow = elements[i + 1].id;
                document
                  .getElementById(this.formShow)
                  .classList.add("form-active");
                this.errorVehicleType = "";
                break;
              } else {
                this.errorVehicleType = "Please select one";
              }
            }

            if (this.formShow == "form2") {
              if (!this.form2.vmodel.fullName) {
                this.form2.error.errorfullName = "Please enter full name";
              } else {
                this.form2.error.errorfullName = "";
              }
              if (!this.form2.vmodel.dob) {
                this.form2.error.errorDOB = "Please enter date of birth";
              } else {
                this.form2.error.errorDOB = "";
              }
              if (!this.form2.vmodel.phone) {
                this.form2.error.errorPhone = "Please enter phone number";
              } else {
                this.form2.error.errorPhone = "";
              }
              if (!this.form2.vmodel.emailaddress) {
                this.form2.error.errorEmailAddress = "Please enter email";
              } else {
                this.form2.error.errorEmailAddress = "";
              }

              if (
                this.form2.vmodel.fullName &&
                this.form2.vmodel.phone &&
                this.form2.vmodel.emailaddress &&
                this.form2.vmodel.dob
              ) {
                element.classList.remove("form-active");
                this.formShow = elements[i + 1].id;
                document
                  .getElementById(this.formShow)
                  .classList.add("form-active");
                this.errorVehicleType = "";
                break;
              }
              if (this.fullname == "") {
                this.errorfullName = "Please enter full name";
              }
            }
            if (this.formShow == "form3") {
              if (!this.form3.vmodel.address) {
                this.form3.error.errorAddress = "Please enter address";
              } else {
                this.form3.error.errorAddress = "";
              }
              if (!this.form3.vmodel.city) {
                this.form3.error.errorCity = "Please enter City";
              } else {
                this.form3.error.errorCity = "";
              }
              if (!this.form3.vmodel.province) {
                this.form3.error.errorProvince = "Please enter province";
              } else {
                this.form3.error.errorProvince = "";
              }
              if (!this.form3.vmodel.postal) {
                this.form3.error.errorPostal = "Please enter postal code";
              } else {
                this.form3.error.errorPostal = "";
              }
              if (!this.form3.vmodel.currentResidence) {
                this.form3.error.errorCurrentResidence =
                  "Please enter years at current residence";
              } else {
                this.form3.error.errorCurrentResidence = "";
              }
              if (!this.form2.vmodel.residenceType) {
                this.form3.error.errorResidenceType =
                  "Please enter residence type";
              } else {
                this.form3.error.errorResidenceType = "";
              }

              if (
                this.form3.vmodel.address &&
                this.form3.vmodel.city &&
                this.form3.vmodel.province &&
                this.form3.vmodel.currentResidence &&
                this.form3.vmodel.postal &&
                this.form3.vmodel.residenceType
              ) {
                element.classList.remove("form-active");
                this.formShow = elements[i + 1].id;
                document
                  .getElementById(this.formShow)
                  .classList.add("form-active");
                this.errorVehicleType = "";
                break;
              }
            }
          }
         else if (ele == "prev") {
          element.classList.remove("form-active");
          this.formShow = elements[i - 1].id;
          document.getElementById(this.formShow).classList.add("form-active");
          break;
        }
        else if(ele=="submit")
        if (this.formShow == "form4") {
              if (!this.form4.vmodel.employmentStatus) {
                this.form4.error.errorEmploymentStatus =
                  "Please provide employment status";
              } else {
                this.form4.error.errorEmploymentStatus = "";
              }
              if (!this.form4.vmodel.tradeIn) {
                this.form4.error.errorTradein =
                  "Please select trade-in(yes or no)";
              } else {
                this.form4.error.errorTradein = "";
              }

              if (
                this.form4.vmodel.employmentStatus &&
                this.form4.vmodel.tradeIn
              ) {
               alert('Form Submited')
                
              }
            }

      }}
    },
  },
  mounted() {
    const inputs = document.querySelectorAll('input[type="text"], textarea, select');

    inputs.forEach(input => {
      input.classList.toggle('has-content', input.value.trim() !== '');
      input.addEventListener('input', () => {
        input.classList.toggle('has-content', input.value.trim() !== '');
      });
    });
  }
};
</script>

<style scoped>
@media only screen and (min-width: 768px) {
  .application-wrapper {
    padding-left: 150px;
    padding-right: 150px;
  }
}
.btn {
  min-width: 120px;
}
.vehicle-type
{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.vehicle-type label {
  width: calc(25% - 20px);
}

@media only screen and (max-width: 767px) {
  .application-wrapper {
    padding-left: 12px !important;
    padding-right: 12px  !important;
  }
  .vehicle-type label {
  width: calc(50% - 10px);
}
.vehicle-type {
  margin-bottom:-20px
}
}
.custom-radio input {
  display: none;
}
.rad-button {
  margin: 10px;
  width: 100%;
  height: auto;
  display: inline-block;
  box-shadow: 0 0 3px 0;
  cursor: pointer;
}
.custom-radio input:checked + .rad-button {
  border: 3px solid #07529d;
}
.progress-bar {
  display: flex;
  align-items: center;
}

.progress-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}

.progress-rect {
  flex-grow: 1;
  height: 5px;
  background-color: #ddd;
  position: relative;
  margin: 0 5px;
}
.progress-bar {
  width: 100%;
}
.progress-initial {
  border: 3px solid #07529d;
}
.color-bg {
  background-color: #07529d;
  color: #fff;
}
</style>