<template>
  <div>
    <NavBar />
    <section>
      <HeroCarousel/>
      <HomeComponent />
    </section>

    <FooterComponent />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import HomeComponent from "@/components/HomeComponent.vue";
import HeroCarousel from "@/components/HeroCarousel.vue";



import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "IndexView",
  components: {
    NavBar,
    FooterComponent,
    HomeComponent,
    HeroCarousel

  },
};
</script>

<style scoped>
</style>