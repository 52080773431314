<template>
  <div
    class="container vdp-container py-5"
    style="min-height: calc(100vh - 262px)"
  >
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
            <div class="image-container" style="position: relative">
              <div class="image-carousel">
                <img :src="vehData.photo" class="fluid-image" />



              </div>
            </div>
            <h2 class="header-color text-start pt-5">
              <b> {{ vehData.year }} {{ vehData.make }} {{ vehData.model }} </b>
            </h2>
            <hr />
            <p class="text-start fw-bolder">{{ vehData.trim }}</p>
            <p class="mb-0 text-start">{{ vehData.description }}</p>
            <h2 class="pt-5 pb-2 header-color"><b> Specifications</b></h2>
            <div class="bg-light details-specifications">
              <div class="d-flex">
                <div><b>Odometer</b></div>
                <div>{{ formattedDistance(vehData.odometer) }}</div>
              </div>
              <div class="d-flex">
                <div><b>Exterior</b></div>
                <div>{{ vehData.exteriorcolor }}</div>
              </div>
              <div class="d-flex">
                <div><b>Interior</b></div>
                <div>{{ vehData.intercolor }}</div>
              </div>
              <div class="d-flex">
                <div><b>Fuel type</b></div>
                <div>{{ vehData.fueltype }}</div>
              </div>
              <div class="d-flex">
                <div><b>Drive</b></div>
                <div>{{ vehData.drive }}</div>
              </div>
              <div class="d-flex">
                <div><b>Engine</b></div>
                <div>{{ vehData.engine }}</div>
              </div>
              <div class="d-flex">
                <div><b>Body</b></div>
                <div>{{ vehData.body }}</div>
              </div>
              <div class="d-flex">
                <div><b>Transmission </b></div>
                <div>{{ vehData.transmission }}</div>
              </div>
              <div class="d-flex">
                <div><b>Passengers </b></div>
                <div>{{ vehData.passengers }}</div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" id="price-box">
            <div class="sticky-top">
              <div
                style="
                  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                  padding: 45px 40px;
                  border-radius: 1rem;
                "
              >
                <div class="">
                  <h2 style="line-height: 1.4">
                    <b style="color: #ed1b23"
                      ><span
                        style="
                          position: relative;
                          top: -0.25em;
                          font-size: 0.7em;
                        "
                        >$</span
                      >{{ formattedPrice(vehData.price) }}
                    </b>
                    <p style="font-size: 12px; color: #999">
                      <sup style="margin-top: 4px">*</sup> licencing & taxes
                      extra
                    </p>
                  </h2>
                  <div class="mt-3">
                    <b class="fs-5" style="display: flex"
                      ><p>Lease starts from :</p>
                      <span style="margin-left: 12px" v-if="vehData && ip"
                        ><LeaseCalculator
                          :initial-price="parseFloat(adjustedPrice)"
                          :vehicle-price="parseFloat(adjustedPrice)"
                          :vehicle-condition="vehData.status"
                          down-payment="2000"
                          terms-selected="1"
                          tax-selected="false"
                          page-type="single"
                      /></span>
                    </b>
                    <p style="color: #ed1b23">
                      <a role="button" @click="showModal = true"
                        ><b>Customize this lease</b></a
                      >
                      <teleport to="#modal-root">
                        <ModalCalculator
                          v-show="showModal"
                          @close="showModal = false"
                        >
                          <div class="container">
                            <div class="row">
                              <div class="col-xs-12 col-sm-12 col-md-8 px-0">
                                <img
                                  :src="vehData.photo"
                                  :alt="
                                    vehData.year +
                                    ' ' +
                                    vehData.make +
                                    ' ' +
                                    vehData.model
                                  "
                                  width="100%"
                                  height="auto"
                                />
                              </div>
                              <div class="col-xs-12 col-sm-12 col-md-4" style="background-color: #fff">
                                <div class="p-4">
                                  <h3 class="header-color">
                                    <b
                                      >{{ vehData.year }} {{ vehData.make }}
                                      {{ vehData.model }}</b
                                    >
                                  </h3>
                                  <hr />
                                  <p>{{ vehData.trim }}</p>
                                  <div
                                    style="display: flex; flex-direction: row"
                                    class="pt-4"
                                  >
                                    <div style="width: 50%">
                                      <h6
                                        style="
                                          display: flex;
                                          align-items: center;
                                          height: 100%;
                                        "
                                      >
                                        <b>Price:</b>
                                      </h6>
                                    </div>
                                    <div style="width: 50%; text-align: end">
                                      <h6>
                                        <span
                                          style="
                                            position: relative;
                                            top: -0.25em;
                                            font-size: 0.7em;
                                          "
                                          >$</span
                                        >{{ formattedPrice(vehData.price) }}
                                      </h6>
                                    </div>
                                  </div>

                                  <div
                                    style="display: flex; flex-direction: row"
                                    class="pt-3"
                                  >
                                    <div style="width: 50%" class="">
                                      <h6
                                        class="h-100 d-flex"
                                        style="align-items: center"
                                      >
                                        <b>Cash Down:</b>
                                      </h6>
                                    </div>
                                    <div style="width: 50%; text-align: end">
                                      <input
                                        type="text"
                                        v-model="cashDown"
                                        class="form-control"
                                        @input="restrictInput"
                                      />
                                    </div>
                                  </div>
                                  <div class="py-4 element-center">
                                    <h4>Lease options</h4>
                                  </div>

                                  <div
                                    style="display: flex; flex-direction: row"
                                    class=""
                                  >
                                    <div style="width: 50%">
                                      <h6
                                        style="
                                          display: flex;
                                          align-items: center;
                                          height: 100%;
                                        "
                                      >
                                        <b>Select Term:</b>
                                      </h6>
                                    </div>
                                    <div style="width: 50%; text-align: end">
                                      <select
                                        name="cars"
                                        id="paymentsterms"
                                        class="mb-0 flex-grow-1 form-select"
                                        style="text-align: right"
                                        v-model="termsSelected"
                                      >
                                        <option value="1">24 months</option>
                                        <option value="2">36 months</option>
                                        <option value="3">48 months</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div
                                    style="display: flex; flex-direction: row"
                                    class="pt-3"
                                  >
                                    <div style="width: 50%" class="">
                                      <h6
                                        class="h-100 d-flex"
                                        style="align-items: center"
                                      >
                                        <b>Include Tax</b>
                                      </h6>
                                    </div>
                                    <div style="width: 50%; text-align: end">
                                      <div
                                        style="
                                          display: flex;
                                          flex-grow: 1;
                                          justify-content: right;
                                          align-items: center;
                                        "
                                      >
                                        <input
                                          type="checkbox"
                                          class="form-check-input"
                                          name="taxc"
                                          id="taxc"
                                          v-model="taxSelected"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="mt-4 p-4"
                                    style="
                                      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px
                                        15px;
                                      background-color: #fcfcfc;
                                    "
                                  >
                                    <div v-if="vehData && ip">
                                      <LeaseCalculator
                                        :initial-price="
                                          parseFloat(adjustedPrice)
                                        "
                                        :vehicle-price="
                                          parseFloat(adjustedPrice)
                                        "
                                        :vehicle-condition="vehData.status"
                                        :down-payment="parseFloat(cashDown)"
                                        :terms-selected="
                                          parseInt(termsSelected)
                                        "
                                        :tax-selected="taxSelected"
                                        page-type="vdp"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ModalCalculator>
                      </teleport>
                    </p>
                  </div>

                  <form id="get-form-details">
                   
                               <div style='font-size:16px;font-weight:700;color:#07529d' v-show="successstatus">{{ success }}</div>


                    <p class="mt-3 mb-2">Inquire about this Vehicle</p>

                    <div class="form-group mb-2">
                      <input
                        value=""
                        class="form-control sm form-control-sm"
                        placeholder="Name"
                         v-model="messageData.fullName"

                      />
                      
                      <small
                        id="name-error"
                        class="text-danger seller-error"
                      >{{error.name}}</small>
                    </div>

                    <div class="form-group mb-2">
                      <input
                        value=""
                        class="form-control sm form-control-sm"
                        placeholder="Mobile Number"
                        v-model="messageData.phone"
                      />
                      <small
                        id="phone-error"
                        class="text-danger seller-error"
                      >{{error.phone}}</small>
                    </div>

                    <div class="form-group mb-2">
                      <input
                        value=""
                        class="form-control sm form-control-sm"
                        placeholder="Email"
                        v-model="messageData.emailAddress"
                      />
                      <small
                        id="email-error"
                        class="text-danger seller-error"
                      >{{error.email}}</small>
                    </div>
                  </form>
                </div>

                <div class="clearfix">
                  <button
                class="button btn"
                type="submit"
                @click="sendMessage"
                value="send"
              >
                Send Message
              </button>
                </div>
              </div>

              <div class="mt-5" style="">
                <div class="details-container">
                  <div class="details-box text-center p-3">
                    <img
                      src="/assets/transmission.png"
                      width="60px"
                      height="60px"
                    />

                    <p class="mt-2 mb-0 fw-bold">{{ vehData.transmission }}</p>

                    <p class="mb-0">Transmisson</p>
                  </div>

                  <div class="details-box text-center p-3">
                    <img
                      src="/assets/gas.png"
                      width="60px"
                      height="60px"
                    />

                    <p class="mt-2 mb-0 fw-bold">{{ vehData.fueltype }}</p>

                    <p class="mb-0">Fuel</p>
                  </div>

                  <div class="details-box text-center p-3">
                    <img
                      src="/assets/odo.png"
                      width="60px"
                      height="60px"
                    />

                    <p class="mt-2 mb-0 fw-bold">
                      {{ formattedDistance(vehData.odometer) }}
                    </p>

                    <p class="mb-0">Mileage</p>
                  </div>

                  <div class="details-box text-center p-3">
                    <img
                      src="/assets/pass.png"
                      width="60px"
                      height="60px"
                    />

                    <p class="mt-2 mb-0 fw-bold">{{ vehData.passengers }}</p>

                    <p class="mb-0">Passengers</p>
                  </div>

                  <div class="details-box text-center p-3">
                    <img
                      src="/assets/engine.png"
                      width="60px"
                      height="60px"
                    />

                    <p class="mt-2 mb-0 fw-bold">{{ vehData.engine }}</p>

                    <p class="mb-0">Engine</p>
                  </div>

                  <div class="details-box text-center p-3">
                    <img
                      src="/assets/exterior.png"
                      width="60px"
                      height="60px"
                    />

                    <p class="mt-2 mb-0 fw-bold">{{ vehData.exteriorcolor }}</p>

                    <p class="mb-0">Exterior</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "vue3-carousel/dist/carousel.css";
// import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import axios from "axios";

import ModalCalculator from "@/components/Inventory/ModalCalculator.vue";
import LeaseCalculator from "@/components/Inventory/LeaseCalculator.vue";

export default {
  name: "VdpComponent",
  components: { ModalCalculator, LeaseCalculator },
  data() {
    return {
      vehData: [],
      showModal: false,
      cashDown: 0,
      terms: 1,
      termsSelected: 1,
      taxSelected: false,
      ip: 0,
      messageData: {
        fullName: "",
        emailAddress: "",
        phone: "",
      },
      error: {},
      success: "",
      successstatus:false
    };
  },
  computed: {
    adjustedPrice() {
      return parseFloat(this.ip) + 1000;
    },
  },
  methods: {
    getVehicle() {
      var stock = this.$route.params.stock;
      const vm = this;
      $.ajax({
        url: "https://vickarvip.ca/searchresults.php?stock=" + stock,
        method: "GET",
        dataType: "json",

        success: (data) => {
          vm.vehData = data;
          vm.ip = parseFloat(data.price);
        },
        error: (error) => {
          console.error("Error fetching data:", error);
        },
      });
    },

    restrictInput() {
      this.cashDown = this.cashDown.replace(/^0+/, "");

      if (!parseInt(this.cashDown, 10)) {
        this.cashDown = 0; // Set cashDown to 0 if it's not a valid integer
      } else if (parseInt(this.cashDown, 10) > parseInt(this.ip, 10)) {
        this.cashDown = this.ip; // Set cashDown to ip if it's greater than ip
      }
    },

    formattedPrice(a) {
      // Convert string to number
      const price = parseFloat(a);
      if (!isNaN(price)) {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "decimal",
          currency: "USD",
          minimumFractionDigits: 0,
          currencyDisplay: "code", // Display currency code instead of symbol
        });
        return formatter.format(price);
      } else {
        return "Invalid Price";
      }
    },
    formattedDistance(a) {
      const distance = parseFloat(a);
      if (!isNaN(distance)) {
        const formatter = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
        });
        return formatter.format(distance) + " kms";
      } else {
        return "Invalid Distance";
      }
    },
     sendMessage(e) {
      e.preventDefault();

      var name = this.messageData.fullName;
      var email = this.messageData.emailAddress;
      var phone = this.messageData.phone;
       var vin = this.vehData.vin;
      const formData = new URLSearchParams();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("vin", vin);
      formData.append("action", "vdp");

      axios({
        method: "post",
        url: "https://vfscentral.xyz/route.php",
        data: formData,
      }).then((res) => {
        if (res.data.status == "error") {
          this.error={};
               this.successstatus = false;
          Object.entries(res.data.errors).forEach(([key, value]) => {
            this.error[key] = value;
          });
        } else {
          this.success = "Successfully sent message";
          this.messageData={},
          this.error={};
          this.successstatus = true

        }
      });
    },
  },
  mounted() {
    this.getVehicle();
     const inputs = document.querySelectorAll(
      'input[type="text"], textarea, select'
    );

    inputs.forEach((input) => {
      // Add initial class based on input value
      input.classList.toggle("has-content", input.value.trim() !== "");

      // Listen for input event to update class
      input.addEventListener("input", () => {
        input.classList.toggle("has-content", input.value.trim() !== "");
      });
    });
  },
};
</script>

<style scoped>
.details-specifications .d-flex div {
  padding: 15px;
  width: 50%;
}
.vdp-container {
  text-align: start;
}
.details-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.details-container div {
  width: calc(100% / 3);
}
@media only screen and (max-width: 767px) {
  #price-box {
    margin-top: 3rem;
  }
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1,
p {
  margin-bottom: 0;
}
</style>