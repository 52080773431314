<template>
    <div>
      <NavBar />
      <TeamComponent/>
      <FooterComponent/>
    </div>
  </template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import TeamComponent from "@/components/TeamComponent.vue";
    export default {
 name: "TeamView",
 components: {
    NavBar,
    FooterComponent,
    TeamComponent
  },

    }
</script>

<style scoped>

</style>