<template>
  <div id="captions" class="carousel slide">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#captions"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#captions"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#captions"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active hero hero-main">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col box-position">
              <div
                style="border: 5px solid rgba(255, 255, 255, 0.9); padding: 5px"
              >
                <div class="hero-box" style="">
                  <div class="">
                    <div class="text-center">
                      <p class="header-color fw-bolder fs-4">
                        Manitoba's Home of Vehicle Leasing<br />
                        and Financial Services
                      </p>
                    </div>
                    <div class="text-center">
                      <p style="padding-top: 10px">
                        <strong
                          >Multiple Credit Solutions<br />
                          Under one roof !
                        </strong>
                      </p>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <router-link to="/about" class="btn btn-sm"
                        >Learn More</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item hero hero-newcomer">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col box-position">
              <div
                style="border: 5px solid rgba(255, 255, 255, 0.9); padding: 5px"
              >
                <div class="hero-box" style="">
                  <div class="">
                    <div class="text-center">
                      <p class="header-color fw-bolder fs-4">
                        Newcomers to Canada
                      </p>
                    </div>
                    <div class="text-center">
                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Competitive Rates
                      </p>
                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Big inventory
                      </p>

                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Fast approval
                      </p>

                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Flexible down payments
                      </p>

                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Early termination
                      </p>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <router-link
                        to="/creditsolutions/newcomer-credit"
                        class="btn btn-sm"
                        >Learn More</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item hero hero-challenged">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col box-position">
              <div
                style="border: 5px solid rgba(255, 255, 255, 0.9); padding: 5px"
              >
                <div class="hero-box" style="">
                  <div class="">
                    <div class="text-center">
                      <p class="header-color fw-bolder fs-4">
                        Challenged Credit
                      </p>
                    </div>
                    <div class="text-center">
                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Competitive Rates
                      </p>
                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Big inventory
                      </p>

                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Fast approval
                      </p>

                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Flexible down payments
                      </p>

                      <p>
                        <i
                          class="fa fa-check fw-bolder header-color"
                          aria-hidden="true"
                        ></i>
                        Early termination
                      </p>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <router-link
                        to="/creditsolutions/challenged-credit"
                        class="btn btn-sm"
                        >Learn More</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="carousel-item">
        <img
          src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/homepage/newcomers.png"
          class="d-block w-100"
          alt="New to Canada"
        />
        <div class="carousel-caption d-none d-md-block">
          <h5>Second slide label</h5>
          <p>Some representative placeholder content for the second slide.</p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/homepage/challenged_credit_hero.png"
          class="d-block w-100"
          alt="Challenged Credit"
        />
        <div class="carousel-caption d-none d-md-block">
          <h5>Third slide label</h5>
          <p>Some representative placeholder content for the third slide.</p>
        </div>
      </div> -->
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#captions"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#captions"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "HeroCarousel",
};
</script>

<style scoped>
/* .text-hero-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
} */

.hero-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  /* s */
}
.box-position {
  display: flex;

  flex-direction: column;
  align-items: end;
  justify-content: end;
  padding: 3rem;
}
.text-hero-box-inside {
  background: rgba(255, 255, 255, 0.9);
}

.hero {
  background-position: center top;
  background-repeat: no-repeat !important;
  background-size: cover;
}
.hero-challenged {
  background-image: url(https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/homepage/challenged_credit_hero.png) !important;
  height: 450px;
}
.hero-newcomer {
  background-image: url(https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/homepage/newcomers.png) !important;
  height: 450px;
}
.hero-main {
  background-image: url(https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/homepage/challenged.png) !important;
  height: 450px;
}
.hero-box p {
  margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .hero-lease {
    height: 400px;
  }

  .box-position {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>