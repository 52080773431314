<template>
  <header style="background-color: #fff">
    <div class="container">
      <div class="row">
        <div
          class="col-12 d-flex flex-row"
          style="padding-bottom: 12px; padding-top: 12px"
        >
          <div class="company-logo element-center">
            <router-link to="/">
              <img
                src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/logo_vfs.png"
                alt="abc-company"
                width="150"
                height="auto"
            /></router-link>
          </div>
          <div class="ham-menu" @click="menuShow = true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="2em"
              viewBox="0 0 448 512"
            >
              <path
                d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
              />
            </svg>
          </div>

          <div
            v-bind:class="menuShow == true ? 'show-menu' : ''"
            class="menu-items"
          >
            <div class="element-center text-hide">
              <img
                src="https://vickarfinancial.ca/wp-content/themes/VickarFinancials/Assets/images/logo_vfs.png"
                alt="abc-company"
                width="150"
                height="auto"
              />
            </div>
            <div>
              <span
                class="btn-close-css"
                style="display: none"
                @click="menuShow = false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.4em"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                  />
                </svg>
              </span>
            </div>

            <ul class="parent-ul">
              <li
                @click="activeItem = activeItem === 'cs' ? '' : 'cs'"
                class="pos-rel"
                role="button"
              >
                <span
                  class="oc-chevron"
                  :class="activeItem === 'cs' ? 'od-chevron' : ''"
                  >Credit Solutions
                </span>
                <ul class="child-li" v-show="activeItem === 'cs'">
                  <router-link to="/credit-solutions/newcomers-credit"
                    ><li @click="menuShow = false">
                      New to Canada
                    </li></router-link
                  >
                  <router-link to="/credit-solutions/challenged-credit"
                    ><li @click="menuShow = false">
                      Challenged Credit
                    </li></router-link
                  >
                </ul>
              </li>
              <li
                @click="activeItem = activeItem === 'bs' ? '' : 'bs'"
                class="pos-rel"
                role="button"
              >
                <span
                  class="oc-chevron"
                  :class="activeItem === 'bs' ? 'od-chevron' : ''"
                  >Business Solutions
                </span>
                <ul class="child-li" v-show="activeItem === 'bs'">
                  <router-link to="/business-solutions/leasing"
                    ><li @click="menuShow = false">
                      Commercial Leasing
                    </li></router-link
                  >
                  <router-link to="/business-solutions/fleet"
                    ><li @click="menuShow = false">
                      Fleet Management
                    </li></router-link
                  >
                </ul>
              </li>

              <li
                @click="activeItem = activeItem === 'inv' ? '' : 'inv'"
                class="pos-rel"
                role="button"
              >
                <span
                  class="oc-chevron"
                  :class="activeItem === 'inv' ? 'od-chevron' : ''"
                  >Inventory</span
                >
                <ul class="child-li" v-show="activeItem === 'inv'">
                  
                  <router-link
                    :to="{ name: 'inventory', query: { status: 'used' } }"
                  >
                    <li @click="itemClicked('used')">Used</li>
                  </router-link>

                  <router-link :to="{ name: 'inventory', query: { sp: 'yes' } }"
                    ><li @click="menuShow = false">Specials</li></router-link
                  >
                </ul>
              </li>
              <li
                @click="activeItem = activeItem === 'abt' ? '' : 'abt'"
                class="pos-rel"
                role="button"
              >
                <span
                  class="oc-chevron"
                  :class="activeItem === 'abt' ? 'od-chevron active' : ''"
                  >About us
                </span>
                <ul class="child-li" v-show="activeItem === 'abt'">
                  <router-link to="/about"
                    ><li @click="menuShow = false">About us</li></router-link
                  >
                  <router-link to="/team"
                    ><li @click="menuShow = false">Our Team</li></router-link
                  >
                  <router-link to="/blogs"
                    ><li @click="menuShow = false">Blogs</li></router-link
                  >
                  <router-link to="/contact"
                    ><li @click="menuShow = false">Contact us</li></router-link
                  >
                </ul>
              </li>
              <li style="padding-right: 0">
                <router-link to="/application"
                  ><span class="btn btn-sm">Apply</span></router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

  <script>
export default {
  name: "NavBar ",
  data() {
    return {
      activeItem: null,
      menuShow: null,
    };
  },
  methods: {
    handleClick(stat) {
      this.menuShow = false;
      this.$emit("status-selected", stat);
    },
    childLi(item) {
      this.activeItem = this.activeItem === item ? null : item;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.menuShow = false;
      }
    },
    itemClicked(item) {
      this.$emit("item-Clicked", item);
    },
  },

  mounted() {},
};
</script>

<style scoped>
.menu-items ul li ul.child-li a li {
  /* padding-bottom: 0 !important; */
}

.menu-items ul li ul.child-li a:last-child li {
  padding-bottom: 1rem !important;
}

.menu-items {
  flex-grow: 1;
}

.menu-items ul {
  list-style-type: none;
}

.menu-items ul li ul.child-li li {
  font-weight: 400;
  text-transform: initial !important;
  border-bottom: 0 !important;
  padding: 0.7rem;
}

.menu-items ul li ul.child-li li:hover {
  background: #07529d;
}

.btn-close-css {
  position: absolute;
  top: 20px;
  right: 20px;
  display: block !important;
  cursor: pointer;
  z-index: 11111111;
}

.btn-close-css:hover svg {
  fill: #023650;
}

.menu-items ul li ul.child-li li:not(:first-child) {
  /* padding-top: 0rem; */
}

@media only screen and (max-width: 991px) {
  .parent-ul li:last-child {
    border-bottom: 0 !important;
  }

  .parent-ul {
    padding-left: 0 !important;
  }

  .menu-items ul li ul.child-li {
    padding-left: 0rem !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .oc-chevron:after {
    display: flex;
    flex-grow: 1;
    justify-content: end;
  }

  .menu-items {
    position: fixed;
    top: 0;
    left: 0;
    padding: 2rem 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    background: #fff;
    color: #000 !important;
    font-size: 1rem;
    letter-spacing: 0.05rem;
  }

  .menu-items ul li {
    padding-left: 20px !important;
  }

  .menu-items ul {
    margin-top: 1rem !important;
  }

  .ham-menu {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
  }

  .menu-items ul.parent-ul li {
    text-align: left;
    font-weight: 700;
    padding-top: 7px;
    padding-bottom: 7px;
    text-transform: uppercase;
    padding: 1.2rem 2.4rem 1rem 0;
    color: #000;
    line-height: 1.2;
    white-space: initial;
    border-bottom: 1px solid #f4f4f4;
  }

  .menu-items {
    display: none;
  }

  .ham-menu {
    display: flex;
  }
}

.show-menu {
  display: block;
}

@media only screen and (min-width: 992px) {
  .menu-items {
    background: #fff;
    letter-spacing: 0.05rem;
    position: unset !important;
    flex-direction: row !important;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .ham-menu {
    display: none;
  }

  .btn-close-css {
    display: none !important;
  }

  .text-hide {
    display: none;
  }

  .menu-items ul.parent-ul {
    display: flex;
    padding: unset !important;
    margin-bottom: 0 !important;
    justify-content: end;
    align-items: center;
  }

  .menu-items ul.parent-ul li {
    border-bottom: none !important;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    font-size: 16px;
  }

  .menu-items ul.parent-ul li:last-child {
    padding-right: 0;
  }

  .pos-rel {
    position: relative;
  }

  .menu-items ul.parent-ul li ul {
    width: 100%;
    position: absolute !important;
    top: calc(100% + 5px);
    left: 0;
    z-index: 11111111111111;
    margin-top: 0 !important;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff !important;
    padding-left: 0 !important;
  }
}

a,
a:visited {
  color: #fff !important;
}
</style>
