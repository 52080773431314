<template>
  <div class="container">
    <div class="row py-5">
      <div class="col-12">
        <h1 class="text-center header-color">New to Canada credit program</h1>
        <p class="text-center">
          Feel settled faster - let us help you buy your first car in Canada.
        </p>
        <hr class="mb-0" />
        <div class="element-center py-5">
          <div class="frame-centre-wrapper">
            <div class="iframe-wrapper">
              <iframe
                src="https://www.youtube.com/embed/TIHTHrj0hzs"
                title="YouTube video player"
                frameborder="0"
                class="iframe-position"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <div class="newcomer-data text-start ">
          <h2 class="header-color">What is newcomer credit program?</h2>
          <div class="answer padding-bottom-4">
            <p class="mb-2">
              No previous vehicle buying experience in Canada? That’s okay, our
              Newcomer Program can give you the opportunity to enjoy a new
              vehicle of your own.
            </p>
            <p class="mb-0">
              Buying a vehicle is one of the first big purchases you may make
              while establishing yourself in Canada. Vickar Financial Services
              is dedicated to help you every step of the way to ensure you get
              into the vehicle that’s right for you.
            </p>
          </div>
          <h5 class="header-color">
            <b
              >You can apply for credit under our newcomer program, if you are:
            </b>
          </h5>
          <div class="answers padding-bottom-4 ms-3">
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i></span
              >A landed immigrant with a Permanent Resident
            </p>
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i></span
              >A foreign worker with a Work Permit
            </p>
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i></span
              >An international student with a Study permit
            </p>
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i
              ></span>
              Don’t hold any of these status documents? You may still qualify
              for our First-Time Buyer Program
            </p>
            <p class="pt-2 mb-0">
              Remember, you can always see us for additional details on
              eligibility.
            </p>
          </div>
          <h5 class="header-color">
            <b
              >To make an informed decision, it helps to do a little homework.
              Make a list of your vehicle needs and wants.
            </b>
          </h5>
          <div class="answers  ms-3">
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i></span
              >How many people – and how much stuff – needs to fit into your
              vehicle?
            </p>
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i></span
              >Which safety features are a “must have”?
            </p>
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i></span
              >What are your fuel/kilometre needs?
            </p>
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i
              ></span>
              How much will insurance be?
            </p>
            <p class="mb-1">
              <span
                ><i class="fa fa-check header-color me-2" aria-hidden="true">
                </i
              ></span>

              Do you want leather seats, an upgraded stereo or navigation
              system?
            </p>
            <p class="pt-2 mb-0">
              Figure out a price range that fits your budget. Once you’ve
              decided what you’re looking for, it’s time to take a look at your
              finances to see how you can make this relationship happen.
            </p>
          </div>

        <div class="cta-buttons element-center padding-top-4" >
            <div>
            <router-link :to="{name: ''}" class="btn me-3   ">Apply Now</router-link>

            <router-link :to="{name: 'contact'}" class="btn">Contact us </router-link>
        </div>

        </div>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewComerComponent",
};
</script>

<style scoped>
</style>