<template>
    <div>
      <NavBar />
      <ContactComponent/>
      <FooterComponent/>
    </div>
  </template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
    export default {
 name: "TeamView",
 components: {
    NavBar,
    FooterComponent,
    ContactComponent
  },

    }
</script>

<style scoped>

</style>