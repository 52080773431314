import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'

import './assets/styles.css'

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

window.$ = window.jQuery = $;

createApp(App).use(router).mount('#app')
