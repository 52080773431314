<template>
  <div>
    <div class="container inventory-data">
      <div class="row">
        <div
          class="left-sidebar dark-color col-md-3 px-4 py-5"
          :class="{ 'left-sidebar-active': isActive }"
        >
          <p class="sidebar-heading">Search Vehicles{{ data }}</p>
          <div class="pb-5">
            <input
              class="form-control"
              v-model="search"
              id="search"
              placeholder="Search"
            />
          </div>

          <p class="sidebar-heading">Filter by price</p>
          <div class="pb-5">
            <PriceSlider
              :price-min="this.price.min"
              :price-max="this.price.max"
              :fixed-maxprice="this.fixedPrice.max"
              :fixed-minprice="this.fixedPrice.min"
              @update-range="handlePrice"
            />
          </div>
          <p class="sidebar-heading">Filter by year</p>
          <div class="pb-5">
            <YearSlider
              :year-min="this.year.min"
              :year-max="this.year.max"
              @update-year="handleYear"
            />
          </div>
          <p class="sidebar-heading">Filter by Brand</p>
          <div class="pb-5">
            <ul>
              <li class="" v-for="(model, key) in models" :key="key">
                <input
                  type="checkbox"
                  :id="key"
                  v-model="make"
                  :value="key"
                  @click="removeModels"
                />
                <label :for="key">{{ capitalizeText(key) }}</label>

                <ul v-if="make.includes(key)" class="mb-2">
                  <li v-for="(mod, key1) in model" :key="key1" class="ms-4">
                    <input
                      type="checkbox"
                      :id="key + '-' + mod"
                      v-model="make"
                      :value="key + '-' + mod.replaceAll(' ', '~')"
                    />
                    <label :for="key + '-' + mod">{{
                      capitalizeText(mod)
                    }}</label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <p class="sidebar-heading">Filter by Condition</p>
          <div class="pb-5">
            <ul>
              <li>
                <input
                  type="checkbox"
                  id="preowned"
                  v-model="condition"
                  value="used"
                />
                <label for="preowned">Pre-owned</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="new"
                  v-model="condition"
                  value="new"
                />
                <label for="new">New</label>
              </li>
            </ul>
          </div>

          <p class="sidebar-heading">Filter by Specials</p>
          <div class="pb-3">
            <ul>
              <li>
                <input
                  type="checkbox"
                  id="specials"
                  v-model="specials"
                  value="yes"
                />
                <label for="specials">Special's</label>
              </li>
            </ul>
          </div>
          <p
            class="filter-pill found"
            @click="toggleResults"
            style="
              position: fixed;
              bottom: 20px;
              left: 50%;
              transform: translateX(-50%);
              z-index: 11111111111;
            "
          >
            {{ count }} vehicles Found
          </p>
        </div>

        <div class="col-12 col-sm-12 col-xs-12 col-md-9 py-5">
          <div><h2 style="mb-0">Inventory</h2></div>

          <div
            class="d-flex mb-3 element-center content-srp"
            style="padding: 12px; background-color: #f5f5f5"
          >
            <div class="flex-grow-1">
              <p class="mb-0 text-start fw-bolder">
                {{ count }} vehicles Found
              </p>
            </div>
            <div>
              <select v-model="sort" id="sort" class="form-select">
                <option value="" selected>Sort</option>
                <option value="pa">Price : Low - High</option>
                <option value="pd">Price : High - Low</option>
                <option value="ya">Year : Low - High</option>
                <option value="yh">Year : High - Low</option>
              </select>
            </div>
          </div>
          <div class="row" v-if="vdata.data !== null">
            <div class="col spinner" v-if="spinnerLoader">hello</div>
            <div
              v-for="(value, key) in vdata.data"
              :key="key"
              class="col-md-6 col-lg-4 col-12 mb-5"
            >
              <router-link
                v-if="value.stock_number"
                :to="{ name: 'vdp', params: { stock: value.stock_number } }"
              >
                <div
                  class="h-100"
                  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                >
                  <div class="">
                    <img
                      :src="value.photo"
                      :alt="value.model"
                      class="w-100 img-fluid"
                    />
                    <div class="d-flex flex-column p-3">
                      <div class="flex-grow-1">
                        <div class="vehicle-title fw-bolder text-capitalize">
                          {{ value.year }} {{ value.make }} {{ value.model }}
                        </div>
                        <hr />
                        <div class="small">
                          {{ value.trim }}
                        </div>

                        <div class="pt-2 small">
                          <strong>{{
                            formattedDistance(value.odometer)
                          }}</strong>
                        </div>
                      </div>

                      <div
                        class="d-flex pt-4"
                        style="justify-content: space-between"
                      >
                        <div
                          class="vehicle-title fw-bolder text-capitalize fw-bolder fs-4 d-flex align-items-end text-danger"
                        >
                          {{ formattedPrice(value.price) }}
                        </div>
                        <div class="vehicle-title small">
                          <LeaseCalculator
                            :initial-price="parseFloat(value.price)"
                            :vehicle-price="parseFloat(value.price)"
                            :vehicle-condition="value.status"
                            :down-payment="2000"
                            :terms-selected="1"
                            :tax-selected="false"
                            page-type="srp"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
              <div v-else>Missing VIN for one or more vehicles.</div>
            </div>
          </div>
          <div v-else>No Records found</div>
          <!-- <div class="element-center" v-if="this.no_pages > 1"> -->
          <div class="element-center">
            <select
              v-model="page"
              class="form-select"
              style="width: unset !important" v-if="no_pages>1"
            >
              <option
                v-for="index in no_pages"
                :key="index"
                :value="index"
                :id="'pagination-li-' + index"

              >
                {{ index }}
              </option>
            </select>
          </div>
          <p
            class="filter-pill"
            style="
              position: fixed;
              bottom: 20px;
              left: 50%;
              transform: translateX(-50%);
              z-index: 111111111;
            "
            @click="toggleActive"
          >
            filter
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LeaseCalculator from "@/components/Inventory/LeaseCalculator.vue";
import PriceSlider from "@/components/Inventory/PriceSlider.vue";
import YearSlider from "@/components/Inventory/YearSlider.vue";

export default {
  name: "InventoryData",
  components: { LeaseCalculator, PriceSlider, YearSlider },
  props: {
    statusCondition: String,
    data: String,
  },
  data() {
    return {
      spinnerLoader: false,
      isActive: false,
      vdata: {},
      count: 0,
      openSubcat: false,
      models: [],
      make: [],
      sort: "",
      condition: ["used", "new"],
      specsials: [],
      search: "",

      price: {
        min: 0,
        max: 200000,
      },
      fixedPrice: {
        min: 0,
        max: 200000,
      },

      year: {
        min: 1980,
        max: 2025,
      },
      isInitialLoadComplete: false,
      page: 1,
      no_pages: 1,
      query: {},
    };
  },
  watch: {
    data(status) {

      this.condition=[];
      this.make=[];
      this.specials='';
      this.year={},
      this.price={},

      this.condition = [status];
      this.updateURL(1);
    },

    make() {
      this.updateURL(1);
      this.page = 1;
    },
    specials() {
      this.updateURL(1);
      this.page = 1;
    },
    condition() {
      this.updateURL(1);
    },
    sort() {
      this.updateURL(1);
      this.page = 1;
    },
    search() {
      // this.updateURL(this.price.min, this.price.max);
      this.updateURL(1);
      this.page = 1;

      // this.updateURL(this.search,this.price.min, this.price.max, this.year.min, this.year.max);
    },
    page() {
      // this.updateURL(this.price.min, this.price.max);
      this.updateURL(this.page);

      // this.updateURL(this.search,this.price.min, this.price.max, this.year.min, this.year.max);
    },
  },

  methods: {
    lowerText(text) {
      return text.toLowerCase();
    },
    capitalizeText(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    removeModels(e) {
      let val = e.target.value;
      if (!e.target.checked) {
        this.make = this.make.filter(
          (v) => !v.startsWith(val + "-") && v !== val
        );
      }
    },

    formattedPrice(a) {
      const price = parseFloat(a);
      if (!isNaN(price)) {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
        });
        return formatter.format(price);
      } else {
        return "Invalid Price";
      }
    },
    formattedDistance(a) {
      const distance = parseFloat(a);
      if (!isNaN(distance)) {
        const formatter = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
        });
        return formatter.format(distance) + " kms";
      } else {
        return "Invalid Distance";
      }
    },
    toggleActive() {
      this.isActive = !this.isActive;
      document.body.classList.add("fixed-body");
    },
    toggleResults() {
      this.isActive = false;
      document.body.classList.remove("fixed-body");
    },
    handleButtonClick(p) {
      alert(p);
    },
    getCarDataSideBar() {
      axios({
        method: "GET",
        url: "https://vickarvip.ca/searchresults.php",
        responseType: "json",
      })
        .then((res) => {
          console.log(res.data);
          this.price.min = res.data.minPrice;
          this.price.max = res.data.maxPrice;
          this.fixedPrice.min = res.data.minPrice;
          this.fixedPrice.max = res.data.maxPrice;
          this.models = res.data.models;
        })
        .catch((err) => console.log(err));
    },

    getVehicleData(query) {
      axios({
        method: "GET",
        url: "https://vickarvip.ca/searchresults.php",
        responseType: "json",
        params: query,
      })
        .then((res) => {
          this.vdata = res.data;
          this.count = res.data.count;
          this.no_pages = Math.ceil(res.data.count / 5);
        })
        .catch((err) => console.log(err));
    },

    handlePrice(newR) {
      this.price.min = parseInt(newR.from);
      this.price.max = parseInt(newR.to);

      this.updateURL();
    },
    handleYear(newR) {
      this.year.min = parseInt(newR.from);
      this.year.max = parseInt(newR.to);

      this.updateURL();
    },
    updateURL(page) {
      var query = {};

      if (this.make.length > 0) {
        query.make = this.make.join(",");
      }
      if (this.condition.length > 0) {
        query.status = this.condition.join(",");
      }
      if (this.page > 1) {
        query.pg = page;
      }

      // if (this.specials.length > 0) {
      //   query.sp = this.specials;
      // }

      if (this.sort.length > 0) {
        query.sort = this.sort;
      }
      if (this.specials) {
        query.sp = this.specials;
      }

      if (this.search) {
        query.search = this.search;
      }

      if (this.price.min !== null && this.price.min !== undefined) {
        query.min = this.price.min;
      }
      if (this.price.max !== null && this.price.max !== undefined) {
        query.max = this.price.max;
      }
      if (this.year.min !== null && this.year.min !== undefined) {
        query.minY = this.year.min;
      }

      if (this.year.max !== null && this.year.max !== undefined) {
        query.maxY = this.year.max;
      }

      this.query = query;

      this.$router.push({
        path: this.$route.path,
        query: query,
      });

      this.getVehicleData(query);
    },
  },
  created() {
    if (this.$route.query.sp) {
      this.specials = this.$route.query.sp;
    }
    if (this.$route.query.pg) {
      this.page = this.$route.query.pg;
    }
    if (this.$route.query.make) {
      this.make = this.$route.query.make.split(",");
    }
    if (this.$route.query.status) {
      this.condition = this.$route.query.status.split(",");
    }
    if (this.$route.query.min) {
      this.price.min = this.$route.query.min;
    }
    if (this.$route.query.max) {
      this.price.max = this.$route.query.max;
    }
    if (this.$route.query.minY) {
      this.year.min = this.$route.query.minY;
    }
    if (this.$route.query.maxY) {
      this.year.max = this.$route.query.maxY;
    }
    this.updateURL(this.page);
  },

  mounted() {
    this.getCarDataSideBar();
    this.isInitialLoadComplete = true;
  },
};
</script>

<style  scoped>
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
body {
  box-sizing: border-box;
}
.sidebar-heading {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 9px;
  border-bottom: solid 1px #777;
  letter-spacing: 0.1rem;
  text-align: left;
}
.inventory-data ul {
  text-align: left;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
label {
  font-weight: 500;
  margin-left: 10px;
}
.inventory-data {
  text-align: left;
}
.filter-pill {
  background-color: #096cee;
  border-radius: 24px;
  bottom: 0;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 12px;
  max-height: 40px;
  padding: 8px 24px;
  color: #fff !important;
  /* position: relative; */
  overflow: hidden;
  transition: bottom 0.3s;
  will-change: bottom;
  white-space: nowrap;
}
a:link,
a:active,
a:visited,
a {
  color: black;
}
.left-sidebar {
  background-color: #fff;
}
@media only screen and (min-width: 768px) {
  .left-sidebar {
    display: block !important;
  }

  .filter-pill {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .left-sidebar {
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 1111111111111;
    right: 0;
    overflow-y: scroll;
    height: calc(100vh - 100px);
  }
}
.left-sidebar-active {
  left: 0 !important;
  z-index: 999999999;
}

.left-sidebar-active .filter-pill {
  display: none;
}
.filter-pill.found {
  display: none;
}
.left-sidebar-active .filter-pill.found {
  display: block;
}
</style>