<template>
  <div>
    <NavBar @item-clicked="handleItemClicked" />
    <InventoryData :data="sharedData" />
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import InventoryData from "@/components/Inventory/InventoryData.vue";

export default {
  name: "InventoryView",
  components: {
    NavBar,
    FooterComponent,
    InventoryData,
  },
  computed: {},

  data() {
    return {
      status: "",

      sharedData: null,
    };
  },
  methods: {
    handleItemClicked(data) {
      this.sharedData = data;
    },
  },
};
</script>

<style scoped>
</style>