import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import InventoryView from '../views/InventoryView.vue'
import VdpView from '../views/VdpView.vue'
import HomeView from '../views/HomeView.vue'
import TeamView from '@/views/TeamView.vue'
import ContactView from '../views/ContactView.vue'
import ApplicationView from '@/views/ApplicationView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import LeaseComponent from '@/components/LeasingComponent.vue'
import BusinessSolutionsView from '@/views/BusinessSolutionsView.vue'
import BusinessSolutionsComponent from '@/components/BusinessSolutionsComponent.vue'
import CreditSolutionsView from '@/views/CreditSolutionsView.vue'
import CreditSolutionsComponents from '@/components/CreditSolutionsComponents.vue'
import CcreditView from '@/components/CcreditView.vue'
import NewComerComponent from '@/components/NewComerComponent.vue'
import FleetComponent from '@/components/FleetComponent.vue'



const routes = [
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: NotFoundView,
    meta: { title: '404 Not Found' } 
  },
  {
    path: '/',
    name: 'index',
    component: IndexView,
    meta: { title: 'Welcome to Vickar Financial Services' } 
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: InventoryView,
    meta: { title: 'Inventory' } 
  },
  // {
  //   path: '/inventory',
  //   name: 'inventory',
  //   component: InventoryView
  // },

  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: { title: 'Contact Us' } 
  },
  {
    path: '/team',
    name: 'team',
    component: TeamView,
    meta: { title: 'Our team' } 
  },
  {
    path: '/inventory/vdp/:stock',
    name: 'vdp',
    component: VdpView,
    meta: { title: 'Home Page' } 
  },
  {
    path: '/homeview',
    name: 'homeview',
    component: HomeView,
    meta: { title: 'Welcome to Vickar Financial Services' } 

  },

  {
    path: '/application',
    name: 'application',
    component: ApplicationView,
    meta: { title: 'Lease Application' } 
  },
 {
  path: '/credit-solutions',
  name: 'cs',
  component: CreditSolutionsView,
  children: [
    {
      path: '', // Default child route when accessing /credit-solutions
      name: 'cs-overview', // Change the name to differentiate this route
      component: CreditSolutionsComponents,
      meta: {
        title: 'Credit Solutions Overview'
      }
    },
    {
      path: 'challenged-credit', // URL: /credit-solutions/challenged-credit
      name: 'cc',
      component: CcreditView,
      meta: {
        title: 'Challenged Credit Solutions'
      }
    },
    {
      path: 'newcomers-credit', // URL: /credit-solutions/newcomers-credit
      name: 'ncc',
      component: NewComerComponent,
      meta: {
        title: 'Newcomer Credit Solutions'
      }
    }
  ]
}
,
  {
    path: '/business-solutions',
    name: 'bs',
    component: BusinessSolutionsView,
    meta: {
      title: 'Business Solutions',   
      requiresAuth: true,            
      description: 'Explore our business solutions including leasing and fleet management.' // Add any custom descriptions or metadata
    },
    children: [
      {
        path: '',
        name: 'bs1',
        component: BusinessSolutionsComponent,
        meta: {
          title: 'Business Solutions Overview'
        }
      },
      {
        path: 'leasing',
        name: 'leasing',
        component: LeaseComponent,
        meta: {
          title: 'Leasing Solutions',
          description: 'Find out more about our leasing services.'
        }
      },
      {
        path: 'fleet',
        name: 'fleet',
        component: FleetComponent,
        meta: {
          title: 'Fleet Management',
          description: 'Manage your fleet with our solutions.'
        }
      }
    ]
  },
  
  {
    path: '/about',
    name: 'about',
    meta: { title: 'About us' } ,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {

    return { left: 0, top: 0 };
  }
})

router.beforeEach((to, from, next) => {
  // Check if there's a title defined in the meta field for the route
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Default Title'; // Set a fallback title if none is defined
  }
  next();
});

export default router
