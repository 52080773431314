<template>
    <footer class="p-4 pt-md-5 border-top dark-color text-light mb-0">
    <div class="container">
        <div class="row">
            <div class="col-sm-9 col-8">
                <div class="row">
                    <div class="col-md-3 col-sm-12 ps-0">
                        <p class="text-start"><a href="/about">About us</a></p>
                    </div>
                    <div class="col-md-3 col-sm-12 ps-0">
                        <p class="text-start"><router-link to="/contact">Contact Us</router-link></p>
                    </div>
                    <div class="col-md-3 col-sm-12 ps-0">
                        <p class="text-start"><a href="/terms-of-use">Terms of use</a></p>
                    </div>
                    <div class="col-md-3 col-sm-12  ps-0">
                        <p class="text-start"><a href="/privacy-policy">Privacy Policy</a></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-4 text-right">
                <div>
                    <a href="https://www.facebook.com/vickarfinacialservices" alt="Facebook"><i class="fa-brands fa-facebook-f fa-2x me-3" aria-hidden="true"></i></a>
                    <a href="https://www.instagram.com/vickarfinancialservices/" alt="Instagram"><i class="fa-brands fa-instagram fa-2x" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col px-0">
                <hr class="bg-secondary">
                <p class="text-center head_color"> &#169; Copyright 2024 Vickar financial services</p>
            </div>
        </div>
    </div>


</footer>
</template>

<script>
    export default {
        name: 'FooterComponent'
    }

</script>




<style  scoped>
footer a {
    color:white
}
</style>