<template>
    <div>
        <NavBar />
    <VdpComponent/>
    <FooterComponent/>

    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import VdpComponent from "@/components/Inventory/VdpComponent.vue";

    export default {
        name:"VdpView",
        components:{ VdpComponent,NavBar,FooterComponent

        }

    }
</script>

<style scoped>

</style>