<template>
    <div>
      <NavBar />
      <ApplicationComponent/>
      <FooterComponent/>
    </div>
  </template>

  <script>
  import NavBar from "@/components/NavBar.vue";
  import FooterComponent from "@/components/FooterComponent.vue";
  import ApplicationComponent from "@/components/ApplicationComponent.vue";


  export default {
    name: "ApplicationView",
    components: {
      NavBar,FooterComponent,ApplicationComponent
    },
  };
  </script>

  <style scoped>
  </style>