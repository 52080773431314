<template>
    <div class="container">
        <div class="row py-5">
          <div class="col-lg-6 col-md-12 element-center pb-5 pb-md-0 pe-md-0">
            <img
              src="/assets/newcomers_loan.png"
              alt="Challenged Credit"
              class="fluid-image"
            />
          </div>

          <div
            class="col-lg-6 col-md-12 element-center p-5"
          >
            <div>
              <h2 class="header-color ">
                Challenged Credit?
              </h2>
              <p class="custom_content">
                We offer all kinds of purchase solutions and are dedicated to
                getting you approved for a vehicle you love and welcoming you
                into our family. Our knowledgeable staff has the experience to
                work with you and make car-buying fun and easy. No matter what
                point of life you're at, Vickar Financial Services will work
                with you to make the best plan. Get into a vehicle you love at
                the right payment for your lifestyle - you deserve it!
              </p>

              <div class="text-center">
                <router-link :to="{ name: 'cc' }"><b>Learn More</b></router-link>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-12 element-center p-5"
          >
            <div>
              <h2 class="header-color">
                Newcomers to Canada
              </h2>
              <p class="custom_content">
                Car Loans for Newcomers to Canada Are you a new immigrant
                looking for a car loan in Canada? Many new immigrants have a
                hard time getting approved for a car loan in Canada. This is not
                uncommon for newcomers as they may not have a Canadian credit
                score or work history, making it hard for them to prove their
                financial eligibility. Vickar Financial Services can help you
                get you approved for a desired vehicle quickly with a simple
                application process.
              </p>

              <div class="text-center">
                <router-link :to="{ name: 'ncc' }"><b>Learn More</b></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 element-center ps-md-0 pb-5 pb-md-0">
            <img
              src="/assets/challenged_Credit.png"
              alt="Challenged Credit"
              class="fluid-image"
            />
          </div>
        </div>

        <div class="row brands">
          <div class="col-12 mb-3">
            <h2>Brands</h2>
          </div>
          <div class='col-lg-1 col-xl-1 d-none d-lg-block'></div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img
              src="/assets/vickar_chevy.png"
              alt="Chevrolet logo"
              class="fluid-image"
            />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img
              src="/assets/vickar_nissan.png"
              alt="Nissan logo"
              class="fluid-image"
            />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img
              src="/assets/vickar_ford.png"
              alt="Ford logo"
              class="fluid-image"
            />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img
              src="/assets/vikar_mitsubishi.png"
              alt="Mitsubishi logo"
              class="fluid-image"
            />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img
              src="/assets/vickar_isuzu.png"
              alt="Isuzu logo"
              class="fluid-image"
            />
          </div>
          <div class='col-lg-1 col-xl-1 d-none d-lg-block'></div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img
              src="/assets/hyundai.png"
              alt="Hyundai logo"
              class="fluid-image"
            />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img src="/assets/honda.png" alt="Honda logo" class="fluid-image" />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img src="/assets/buick.png" alt="Buick logo" class="fluid-image" />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img
              src="/assets/toyota.png"
              alt="Toyota logo"
              class="fluid-image"
            />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img src="/assets/kia.png" alt="Kia logo" class="fluid-image" />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img src="/assets/gmc.png" alt="GMC logo" class="fluid-image" />
          </div>

          <div class='col-lg-2 col-xl-2 d-none d-lg-block'></div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img src="/assets/bmw.png" alt="BMW logo" class="fluid-image" />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img
              src="/assets/landrover.png"
              alt="Land Rover logo"
              class="fluid-image"
            />
          </div>

          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img src="/assets/lexus.png" alt="Lexus logo" class="fluid-image" />
          </div>
          <div class="col-lg-2 col-4 col-xs-4 col-sm-4 col-md-3">
            <img src="/assets/volvo.png" alt="Volvo logo" class="fluid-image" />
          </div>
          <div class='col-lg-2 col-xl-2 d-none d-lg-block'></div>        </div>
      </div>
</template>

<script>
    export default {

        name:'CreditSolutionsComponent'
    }
</script>

<style lang="scss" scoped>

</style>