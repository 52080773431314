<template>
  <div>
    <section class="container-fluid">
      <div class="row photo-contact contact-height" style="position: relative">
        <div
          class="col text-center d-flex justify-content-center align-items-center contact-height"
        >
          <h1 class="text-white"><strong>Contact Us</strong></h1>
        </div>
      </div>
    </section>
    <div class="container pt-5">
      <div class="row">
        <div
          class="col-lg-6 col-md-6 mb-5 p-5"
          style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        >
          <form id="form-contact">
            <p class="sidebar-heading-contact mb-3 fs-3">SEND MESSAGE</p>
            <div style='font-size:24px;padding:20px;color:#07529d' v-show="successstatus">{{ success }}</div>
            <div class="mb-4" style="position: relative">
              <input
                type="text"
                v-model="messageData.fullName"
                class="w-100"
                placeholder=""
              />
              <span class="input-details">Full name</span>
              <small class="text-danger">{{ error.name }}</small>
            </div>
            <div class="mb-4" style="position: relative">
              <input
                type="text"
                class="w-100"
                v-model="messageData.emailAddress"
                name="emailaddress"
                placeholder=""
              />
              <span class="input-details">Email</span>
              <small class="text-danger">{{ error.email }}</small>
            </div>
            <div class="mb-4" style="position: relative">
              <textarea
                rows="4"
                name="message"
                v-model="messageData.message"
              ></textarea>
              <span class="input-details">Type your message...</span>
            </div>
            <div>
              <button
                class="button btn"
                type="submit"
                @click="sendMessage"
                value="send"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 mb-5 px-lg-5">
          <div class="d-flex flex-row mb-5">
            <div class="element-center me-4">
              <i class="fa-solid fa-house fa-3x"> </i>
            </div>
            <div style="text-align: left" clas="element-center">
              <div>
                <p class="sidebar-heading-contact mb-1">Address</p>
                <p class="mb-1">1521 dugald rd,</p>
                <p class="mb-1">Winnipeg, MB, R2J 0H3</p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row mb-5">
            <div class="element-center me-4">
              <i class="fa-solid fa-phone fa-3x"> </i>
            </div>
            <div style="text-align: left" class="element-center">
              <div>
                <span class="sidebar-heading-contact mb-1">Phone</span>
                <p class="mb-1">+1 204-515-0757</p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row mb-5">
            <div class="element-center me-4">
              <i class="fa-solid fa-envelope fa-3x"> </i>
            </div>
            <div style="text-align: left" class="element-center">
              <div>
                <p class="sidebar-heading-contact mb-1">Email</p>
                <p class="mb-1">jason.rajpoot@vickar.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import axios from "axios";

export default {
  name: "ContactComponent",
  components: {},
  data() {
    return {
      messageData: {
        fullName: "",
        emailAddress: "",
        message: "",
      },
      error: {},
      success: "",
      successstatus:false
    };
  },
  methods: {
    sendMessage(e) {
      e.preventDefault();

      var name = this.messageData.fullName;
      var email = this.messageData.emailAddress;
      var message = this.messageData.message;
      const formData = new URLSearchParams();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("message", message);
      formData.append("action", "contact");

      axios({
        method: "post",
        url: "https://vfscentral.xyz/route.php",
        data: formData,
      }).then((res) => {
        if (res.data.status == "error") {
          this.error={};
               this.successstatus = false;
          Object.entries(res.data.errors).forEach(([key, value]) => {
            this.error[key] = value;
          });
        } else {
          this.success = "Successfully sent message";
          this.messageData={},
          this.error={};
          this.successstatus = true

        }
      });
    },
  },
  created() {},
  mounted() {
    const inputs = document.querySelectorAll(
      'input[type="text"], textarea, select'
    );

    inputs.forEach((input) => {
      // Add initial class based on input value
      input.classList.toggle("has-content", input.value.trim() !== "");

      // Listen for input event to update class
      input.addEventListener("input", () => {
        input.classList.toggle("has-content", input.value.trim() !== "");
      });
    });
  },
};
</script>
<style scoped>
.photo-contact {
  background: url("/public/assets/ourteam.jpg") !important;
  padding: 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 200px;
  color: white;
}
@media only screen and (max-width: 767px) {
  .photo-contact {
    background: url("/public/assets/ourteam.jpg") !important;
    padding: 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 150px;
    color: white;
  }
}
</style>