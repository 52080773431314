<template>
<div class="modal-overlay">

<slot></slot>
<span class="close-button">

            <a  role='button' style='color:red' @click ="$emit('close')">Close</a>

        </span>
    </div>
</template>

<script>
    export default {
        name:'ModalCalculator',
        props:{
            msg: String
        },
        emits:['close'],

    }
</script>

<style  scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
   z-index:123123123;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y:scroll;
}
.close-button
{
    position: fixed;
    top:20px;
    right:20px;
    z-index:19999999999

}
</style>